.customStyleForSearchInDesktop {
    float: left !important;
    margin-top: 18px !important;
    /*height: 50px;*/
    width: 300px;
}

#desktop-search-field {
    /* Bootstrap form control css */
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#desktop-search-field:hover {
    border: 1px solid #F18D00;
    box-shadow: 0px 0px 0px 3.2px rgba(241, 141, 0, 0.1);
    outline: none;
}

#desktop-search-field:active {
    border: 1px solid #F18D00;
    box-shadow: 0px 0px 0px 3.2px rgba(241, 141, 0, 0.1);
    outline: none;
}

#desktop-search-field:focus {
    border: 1px solid #F18D00;
    box-shadow: 0px 0px 0px 3.2px rgba(241, 141, 0, 0.1);
    outline: none;
}

.customStyleForSearchInMobile {
    float: right !important;
}

#mobile-search-field {
    /* Bootstrap form control css */
    display: block;
    width: 90%; /* We may want to set it to 100% later, if search field is not being visible directly at the topbar */
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #FFFFFF;
    background-color: #1A1A27;
    background-clip: padding-box;
    border: 1px solid #9E9E9E;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#mobile-search-field:hover {
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 0px 3.2px rgba(241, 141, 0, 0.1);
    outline: none;
}

#mobile-search-field:active {
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 0px 3.2px rgba(241, 141, 0, 0.1);
    outline: none;
}

#mobile-search-field:focus {
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 0px 3.2px rgba(241, 141, 0, 0.1);
    outline: none;
}