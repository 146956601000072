#settings-nested-dropdown {
    background-color: #F18D00 !important;
    border-color: #F18D00 !important;
    color: #FFFFFF !important;
    border: none;
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-item {
    min-width: 200px;
}

.dropdown-item:hover {
    background-color: #F18D00 !important;
    color: #FFFFFF !important;
}

.dropdown-item:focus {
    background-color: #F18D00 !important;
    color: #FFFFFF !important;
}