// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";
  
// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

body {
  overflow: auto !important;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.topbar {
  margin-left: auto;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.brand {
  background-color: #ffffff !important;
}

@media (min-width: 1400px){
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1600px;
  }
}
.card.card-custom {
  box-shadow: 0 1px 2px rgba(39, 39, 53, 0.1);
  font-family: "Open Sans", serif;
  color: #12100C;
  min-height: 54rem;
  height: auto;
  margin-bottom: 0 !important;
  margin-top: 0.5rem;
}

//.card-header {
//  border: none !important;
//}

.list-filter-control {
  margin-bottom: 2rem;
}

.list-download-icon {
  padding: 6px !important;
}

.list-download-icon:hover {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 50%;
}

.list-filter-icon:hover {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 50%;
}

input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radio button appearance */
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  border-radius: 50%;
  transition: border-color 0.15s ease-in-out;
}

input[type="radio"]:checked {
  outline: 0;
  border: 1px solid #F18D00;
  background-color: #F18D00;
  box-shadow: 0 0 1px 1px #F18D00 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input[type="radio"]:focus {
  outline: 0;
}

.list-toolbar-actions-part {
  
  input[type="text"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom text input appearance */
    display: inline-block;
    width: 180px;
    height: 30px;
    border: 1px solid #bbbbbb;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out;
  }

  input[type="text"]:focus {
    outline: 0;
    border: 1px solid #F18D00;
    box-shadow: 0 0 3px 1px #F18D00 !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.customer-status-inactive {
  margin-left: 2.2rem;
}

.list-filter-reset-button {
  color: rgb(128, 128, 128) !important;
  border: 1px solid rgb(128, 128, 128) !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.cancel-button {
  color: #fff !important;
  background-color: #8E889C !important;
}

.list-toolbar {
  margin-bottom: 3rem !important;
}

.list-toolbar-actions {
  //padding: 0;
  padding: 4px 0 4px 4px !important;
  cursor: pointer;
}

.filtered-param-display-box {
  margin-bottom: 4rem !important;
}

.list-filtered-params {
  padding: 0 !important;
}

.list-filter-error-message {
  margin-left: 0.5rem;
}

.sidebar-menu-item {
  color: #fff !important;
}

.list-bold-text {
  font-weight: bold !important;
}

.solbox-profile-tab {
  color: #F18D00 !important;
}

.solbox-tab-content {
  margin-top: 3rem;
}

.sol-text {
  width: 100%;
  color: #F18D00;
}

.sol-text:hover {
  width: 100%;
  color: #F18D00;
}

.normal-text {
  width: 100%;
  color: #8C8C9B;
}

.currency-icon-box {
  height: 45px !important;
  width: 45px !important;
  display: block;
  background: #FFEED6;
  border-radius: 5px;

  .currency-icon {
    display: block;
    padding: 1rem;
  }
}

.currency-value-box-container {
  padding: 0 !important;

  .currency-value-box-content {
    width: 100px;

    .currency-value {
      display: block;
      padding-left: 10px;
      padding-top: 3px;
    }
    
    .currency-text-description {
      display: block;
      padding-left: 10px;
      padding-top: 3px;
    }
  }
}

.node-type-cell-container {
  width: 36px;
  height: 28px;
  background-color: rgb(46, 121, 138);
  color: #FFEED6;
  border-radius: 4px;
  font-weight: bold;

  .node-type-cell {
    display: inline-block;
    padding: 0.4rem;
  }
}

.solbox-profile-details {
 cursor: pointer;
}

.transaction-status-failed {
  border: 1px solid rgba(203, 21, 43, 1);
  border-radius: 3px;
  padding: 0.2rem 1rem;
  color: rgba(203, 21, 43, 1);
  background-color: #FEECED;  
  font-weight: bold;
  text-transform: capitalize;
} 

.custom-text-input-style {
  width: 100% !important;
}

@keyframes blink {50% { color: transparent }}
.loader-dot { animation: 1s blink infinite }
.loader-dot:nth-child(2) { animation-delay: 250ms }
.loader-dot:nth-child(3) { animation-delay: 500ms }

.sol-daterange-picker {
  width: fit-content;
}

.check-live-data-modal {
  background: none !important;
}

.inter-module-link {
  cursor: pointer;
}

.success-text {
  color: rgba(11, 110, 79, 1);
}

.disable-input {
  background-color: #E9ECEF;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.sol-tab.active{
  color: #F18D00 !important;
}
.sol-tab:hover{
  color: #12100C !important;
}
.sol-tab{
  border-radius: 7px !important;
}

.CalendarDay__selected_span {
  background: #FEF2D5;
  color: #12100C;
  border: 1px solid #F18D00; 
}

.CalendarDay__selected {
  background: #F18D00;
  color: white;
  border: 1px solid #F18D00;
}

.CalendarDay__selected_span:hover {
  background:#FEF2D5;
  color: #12100C;
  border: 1px solid #F18D00; 
}

.CalendarDay__selected:hover {
  background: #F18D00;
  color: white;
  border: 1px solid #F18D00;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #FEF2D5 !important;
  color: #12100C;
  border: 1px solid #F18D00;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #F18D00 !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
}

.breadcrumb .breadcrumb-item a{
  color: #F18D00 ;
}

//.breadcrumb-item:hover {
//  color: #F18D00 !important;
//}


.reset-button-style {
  border: 1px solid #8C8C9B !important;
  margin-left: 1rem;
  color: #8C8C9B !important;
  //background: #FFFFFF !important;
}

.MuiMenuItem-root {
  font-family: "Open Sans", serif !important;
}

.apexcharts-title-text {
  font-weight: 900 !important;
}


.clipboard-style {
  color: #636363;
  position: relative;
  display: inline-block;
  margin-top: 10px;
  transition: all .2s ease-out;
  border: 2px solid transparent;
  &:hover {
    cursor: pointer;
    background-color:  #c6c6c6;
    border-radius: 5px;

    &:before {
      opacity: 1;
    }
  }
}

/* Following styling are only for the multiple organization select field at Access Management Modal */
.react-select-sol-style-access-management__multi-value__label {
  background-color: #F18D00;
  color: #FFFFFF !important;
}

.react-select-sol-style-access-management__multi-value__remove {
  background-color: #F18D00;
  color: #8C8C9B;
  :hover {
    background-color: #f1c200 !important;
    color: #000000 !important;
  }
}

/* Vertical scrollbar color */
.VAYgw {
  background-color: #F18D00 !important;
}

.eSwYtm {
  background-color: #F18D00 !important;
}

/* Update anchor tag color */
a {
  color: #F18D00 !important;
}