.regular-text-fonts {
    font-family: Open Sans, sans-serif !important;
    font-style: normal !important;
    line-height: 29px !important;
    /* identical to box height, or 120% */
    letter-spacing: 0.25px !important;
    /* Text/01. Body */
    color: #12100C !important;
}
.sol-regular {
    font-weight: normal;
    font-size: 13px;
}
.sol-regular-bold {
    font-weight: normal;
    font-size: 16px;
}

