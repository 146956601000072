.solRadioInput {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radio button appearance */
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  border-radius: 50%;
  transition: border-color 0.15s ease-in-out;
}

.solRadioInput:checked {
  outline: 0;
  border: 1px solid #F18D00;
  background-color: #F18D00;
  box-shadow: 0 0 1px 1px #F18D00 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.solRadioInput:focus {
    outline: 0;
}