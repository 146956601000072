#sm-nested-dropdown {
    background-color: #F18D00 !important;
    border-color: #F18D00 !important;
    color: #FFFFFF !important;
    border: none;
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-item {
    min-width: 200px;
}

.dropdown-item:hover {
    background-color: #F18D00 !important;
    color: #FFFFFF !important;
}

.dropdown-item:focus {
    background-color: #F18D00 !important;
    color: #FFFFFF !important;
}

#terminate {
    background-color: #cb152b !important;
    border-color: #cb152b !important;
    color: #ffffff !important
}

.custom-style {
    z-index: 1055 !important;
}

.custom-icon-holder {
    background-color: #F0F8FA;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center
}