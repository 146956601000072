/* Color codes used here, have been used in summary react-calendar and also in showcasing due limit reached text*/
.custom-control-input:checked~.custom-control-label::before {
    border-color: #0b6e4f !important;
    background-color: #0b6e4f !important;
}

.custom-control-input:focus:checked~.custom-control-label::before {
    border-color: #0b6e4f !important;
    background-color: #0b6e4f !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #8c8c9b !important;
    background-color: #8c8c9b !important;
}

.custom-control-input:not(:checked)~.custom-control-label::before {
    border-color: #8c8c9b !important;
    background-color: #8c8c9b !important;
}